import React from 'react';
import Slider from 'react-slick';
import './Contact.css';
import Mapa from "../mapa/Mapa";
import MainBtn from '../MainBtn/MainBtn';
import ws from "../../assets/imgs/ws.webp";
import ws2 from "../../assets/imgs/ws2.webp";
import ws3 from "../../assets/imgs/ws3.webp";
import ws4 from "../../assets/imgs/ws4.webp";
import ws5 from "../../assets/imgs/ws5.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Contact({ socialLinks }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Mostra 3 imagens por vez por padrão
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // Mostra 2 imagens por vez em telas menores que 1024px
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768, // Mostra apenas 1 imagem por vez em telas menores que 768px
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const images = [ws, ws2, ws3, ws4, ws5];

  return (
    <div className='contact-wrapper'>
      <div className='contact-container'>
        <div className='contact-content'>
          <h2>A Welcoming and Unique Space</h2>
          <p>For self-reconnection through beauty and self-care</p>
        </div>
      
        <div className='contact-img'>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className='image-slide'>
                <img src={image} alt={`slide-${index}`} />
              </div>
            ))}
          </Slider>
        </div>
        <br />
        <br />
        <div className='contact-infos'>
          <div className='location-section'>
            <h3>Location:</h3>
            <br />
            <a href="https://www.google.com/maps/place/Keila+Domingues+-+Skin+Care+-+Cooper+City/@26.0436236,-80.2737122,15z/data=!4m2!3m1!1s0x0:0xcf5dd520258b7c30?sa=X&ved=1t:2428&ictx=111">9620 Stirling Rd Suite 106, Cooper City, FL 33024</a>
          </div>
          <br />
          <div className='hours-section'>
            <br />
            <h3>Our Hours:</h3>
            <br />
            <p>
            Monday - By Appointment Tuesday 9AM-7PM
            </p>
            <br />
            <p>Wednesday 9AM-7PM</p>
            <br />
            <p>Thursday 9AM-7PM</p>
            <br />
            <p>Friday 9AM-7PM</p>
            <br />
            <p>Saturday 9AM-4PM</p>
          </div>
          <br />
          <br />
          <div className="social-links2">
            <div className="follow">
              <h3>Follow Us</h3>
            </div>
            <div className="icons">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className={`${link.icon}`} aria-label={link.label}></i>
                </a>
              ))}
            </div><MainBtn link="tel:+13053704624" alt={true} children='Call Today' />
          </div>
          
          
        </div>
        <br />
        <br />
        
        <Mapa />
      </div>
    </div>
  );
}

export default Contact;
