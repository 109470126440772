const Link = 'Link';
const Scroll = 'Scroll';

const menuItems = [
  
  {
    label: 'Special Offers',
    type: Scroll,
    to: 'specialoffers',
  },{
    label: 'Welcome',
    type: Scroll,
    to: 'home',
  },
  {
    label: 'Quick Review',
    type: Scroll,
    to: 'alltreatments',
  },
  {
    label: 'About Keila',
    type: Scroll,
    to: 'aboutkeila',
  }
];

export default menuItems;

