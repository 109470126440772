import React, { useState, useEffect } from "react";
import "./AboutKeila.css";
import About1 from "../../assets/imgs/aboutkeila3.jpeg";
import About2 from "../../assets/imgs/aboutkeila2.jpeg";
import About3 from "../../assets/imgs/about3.jpeg";
import About4 from "../../assets/imgs/about2.jpeg";

function AboutKeila() {
  const images = [About1, About2, About3, About4];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="aboutkeila-wrapper">
      <div className="aboutkeila-container">
        <div className="aboutkeila-content">
          <div className="keila-bio">
            <h2>About Keila Domingues</h2>
            <h5>Get to Know Your Esthetician</h5>
            <div className="biokei">
              <p>
              Facial Skin Care Specialist offering customized facials for men and women. Our treatments include acne treatment, anti-aging solutions, melasma care, hydrafacials, facial waxing, microdermabrasion, chemical peels, and more. Call today to rejuvenate your skin with high quality and personalized care from our compassionate and dedicated team.

              </p>
              <p>
              Keila Domingues, an esteemed esthetician renowned for her skincare and health expertise emphasizes ethical client care and innovative solutions. She educates clients on effective skincare routines, ensuring personalized treatments crafted or designed to individual needs.



              </p>
              <p>
              Using advanced non-invasive procedures, Keila addresses a broad range of skincare issues that were once exclusively treated by dermatologists. Her approach focuses on preventive care, identifying and treating issues through a meticulous skin analysis and recommending tailored treatment plans.

              </p>
              <p>
              Located in Cooper City, Florida, Keila Domingues Facial Spa utilizes top-tier equipment and authentic aesthetic products specializing in rejuvenating treatments for acne, scars, melasma, and more, the spa promotes overall wellness and natural beauty for clients of all ages and genders.


              </p>
              
            </div>
          </div>
        </div>
        <div className="aboutkeila-img">
          <img src={images[currentImageIndex]} alt="About Keila" />
        </div>
      </div>
    </div>
  );
}

export default AboutKeila;
