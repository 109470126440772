import React from 'react';
import './Cta2.css';
import MainBtn from '../MainBtn/MainBtn';
import tec1 from "../../assets/imgs/casamara.svg"
import tec2 from "../../assets/imgs/hydra.svg";
import tec3 from "../../assets/imgs/derma.svg";
import tec4 from "../../assets/imgs/chris.svg";
import tec5 from "../../assets/imgs/candela.svg";
import tec6 from "../../assets/imgs/tizo.svg";
import tec7 from "../../assets/imgs/swiss.svg"

function Cta2() {
  return (
    <div className='cta2-wrapper'>
      <div className='cta2-container'>
        <div className='cta2-content'>

          <img src={tec1} alt="" />
          <img src={tec2} alt="" />
          <img src={tec3} alt="" />
          <img src={tec4} alt="" />
          <img src={tec5} alt="" />
          <img src={tec6} alt="" />
          <img src={tec7} alt="" />
          
        </div>
      
      </div>
    </div>
  );
}

export default Cta2;
