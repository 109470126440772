import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AllTreatments.css";
import MainBtn from "../MainBtn/MainBtn";
import sk1 from "../../assets/imgs/about.jpeg";
import sk2 from "../../assets/imgs/foto2.png";
import skm1 from "../../assets/imgs/keila3.webp";
import skm2 from "../../assets/imgs/foto2mobile.png";

const main = process.env.REACT_APP_MAIN;

function AllTreatments({ handleNavigationBtn }) {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Cleanup event listener
    return () =>
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const images = [
    { src: sk1, title: "Laser, Injectable, and Anti-Aging" },
    { src: sk2, title: "Facial High Quality Treatments" },
  ];

  const imagesMobile = [
    { src: skm1, title: "Laser, Injectable, and Anti-Aging" },
    { src: skm2, title: "Facial High Quality Treatments" },
  ];

  const displayedImages = isMobile ? imagesMobile : images;

  const treatments = [
    "HydraFacial Treatment",
    "Anti-Aging Regenerating Treatment",
    "Acne Treatment",
    "Chemical Peel",
    "Microdermabrasion for Face and Neck",
    "Melasma Treatment",
    "Deep Cleansing Facial",
    "Eyebrow Lift",
    "Candela Microneedling with Monodose",
    "And More",
  ];

  return (
    <div className="alltreatments-wrapper">
      <div className="title-services">
        <h1>Skin Care Facial Spa</h1>
        <p>Located in Cooper City</p>
        <h5>Here's a quick overview of our services.</h5>
      </div>
      <div className="alltreatments-container">
        <div className="alltreatments-img">
          {displayedImages.map((image, index) => (
            <div key={index} className="image-container">
              <img className="img-meninas" src={image.src} alt={image.title} />
              <div className="image-overlay">
                <span className="image-title">{image.title}</span>
              </div>
            </div>
          ))}
        </div>{" "}
        <div className="treatment-cards">
          {treatments.map((treatment, index) => (
            <div key={index} className="treatment-card">
              <div className="treatment-overlay">
                <span className="treatment-title">{treatment}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="alltreatments-content">
          <p>
            Facial skin care specialist offering customized facials for men and
            women. Our treatments include acne care, anti-aging solutions,
            melasma care, HydraFacials, facial waxing, microdermabrasion,
            chemical peels, and more. Call today to rejuvenate your skin with
            high-quality care and personalized attention from our compassionate
            and dedicated team.
          </p>

          <MainBtn
            handleNavigationBtn={handleNavigationBtn}
            children="Talk With Us"
          />
        </div>
      </div>
    </div>
  );
}

export default AllTreatments;
