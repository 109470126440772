import React from 'react';
import './Hero.css';
import MainBtn from '../MainBtn/MainBtn';

const Hero = ({ handleNavigationBtn }) => {

  return (
    <div className='hero-wrapper2'>
      <div className='hero-overlay2'></div>
      <div className='hero-container2'>
        <div className='hero-content'>
          <div className="texts-hero">
            <div>
              <br />
              <br />
              <h1>Facial Spa - Keila Domingues</h1>
              <h2>Skin Care in Cooper City</h2>
            </div>
            <p>
            Discover a new sensation of skin care and sophistication.
            </p>
            <br />
<br />
            <MainBtn handleNavigationBtn={handleNavigationBtn} children='Contact Us' />
          </div>
        </div>

     
      </div>
    </div>
  );
};

export default Hero;

