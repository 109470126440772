import React from 'react';
import './Appointment.css';
import MainBtn from '../MainBtn/MainBtn';

function Appointment({ handleNavigationBtn }) {
  return (
    <div className='appointment-wrapper'>
      <div className='appointment-container'>
        <div className='appointment-content'>
          <div className="appoint-titles">
              <h2> Appointment</h2>
              <h4>Book your appointment</h4>
              <MainBtn handleNavigationBtn={handleNavigationBtn} children='Contact Us'/>
          </div>
          
        </div>
        
      </div>
    </div>
  );
}

export default Appointment;
