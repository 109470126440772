import React, { useState, useEffect } from "react";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import MainBtn from "../MainBtn/MainBtn";
import Logo from "../../assets/imgs/logo.png";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import "./Header.css";
import Text from "../Texto3/Texto3"

const Header = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoLink = {
    label: "null",
    type: "Scroll",
    to: "inicio",
  };

  return (
    <div className={`${isScrolled ? "scrolled" : ""} header-wrapper`}>
      <Text />
      <header className="header-container">
        <div className="header-img-container">
          <img
            className="header-img"
            src={Logo}
            alt="Skin Care Keila Domingues"
            onClick={() => handleNavigationBtn(logoLink)}
          ></img>
        </div>

        <div className="nav-container">
          <nav>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <button onClick={() => handleNavigationBtn(item)}>
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
         
          {/* <div className='social-links'>
            {socialLinks.map((link, index) => (
              <a key={index} href={link.url} target='_blank' rel='noreferrer'>
                <i className={`${link.icon}`} aria-label={link.label}></i>
              </a>
            ))}
          </div> */}
        </div>
        <button className="menu-toggle" onClick={toggleSidebar}>
          <img src={MenuBars} alt="Menu Bars" />
        </button>
      </header>
      <Menu
        handleNavigationBtn={handleNavigationBtn}
        menuItems={menuItems}
        socialLinks={socialLinks}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Header;
