import React, { useRef, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Hero from "./components/hero/Hero";
import HeroServices from "./components/heroservices/HeroServices";
import HeroContact from "./components/herocontact/HeroContact";
import menuItems from "./components/shared/data/menuItems";
import socialLinks from "./components/shared/data/socialLinks";
import handleNavigationBtn from "./components/shared/utils/handleNavigationBtn";
import "./App.css";
import Instagram from "./components/instagram/Instagram";
import "./assets/styles/Reset.css";
import Home from "./components/home/Home";
import Contact from "./components/contact/Contact";
import FacialTreatments from "./components/facialtreatments/FacialTreatments"

import Especialidades from "./components/especialidades/Especialidades";
import AllServices from "./components/allservices/AllServices";
import All from "./components/alltreatments/AllTreatments";
import About from "./components/aboutkeila/AboutKeila";
import Testemonials from "./components/testimonials/Testimonials";
import Cta2 from "./components/cta2/Cta2";
import Appointment from "./components/appointament/Appointment";

function App() {
  const main = process.env.REACT_APP_MAIN || "/";
  const navigate = useNavigate();
  const location = useLocation();
  const [refs, setRefs] = useState({});

  useEffect(() => {
    const setupRefs = () => {
      const newRefs = {};
      menuItems.forEach((item) => {
        if (item.type === "Scroll") {
          newRefs[item.to] = React.createRef();
        }
      });
      newRefs['specialoffers'] = React.createRef(); // Adicione a referência para "offers"
      setRefs(newRefs);
    };
    setupRefs();
  }, []);

  const handleNavigation = async (item) => {
    await handleNavigationBtn(item, refs, navigate, location);
  };

  useEffect(() => {
    if (location.pathname.endsWith('specialoffers') && refs.offers?.current) {
      refs.offers.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname, refs]);

  return (
    <div className="App">
      <Header
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />
      <Routes>
        <Route
          path={main}
          element={
//hero, home, all, appoin
            <div className="body-wrapper">
              <Hero handleNavigationBtn={handleNavigation} />
              <div ref={refs.specialoffers}>
                <Especialidades />
              </div>

              <div ref={refs.home}>
                <Home handleNavigationBtn={handleNavigation} />
              </div>

              <div ref={refs.alltreatments}>
                <All handleNavigationBtn={handleNavigation} />
                
              </div>

              <div ref={refs.aboutkeila}>
                <About />
                <Cta2 />
              </div>

              <div ref={refs.instagram}>
                <Instagram />
              </div>

              <div ref={refs.testimonials}>
                <Testemonials />
              </div>
              
            </div>


          }
        />

        <Route
          path={main + "allservices"}
          element={
            <div className="body-wrapper">
              <div>
              {/*   <HeroServices />
                <AllServices />
                <FacialTreatments /> */}
              </div>
            </div>
          }
        />

        <Route
          path={main + "contact"}
          element={
            <div className="body-wrapper">
              <div>
                <HeroContact />
                <Contact socialLinks={socialLinks} />
              </div>
            </div>
          }
        />
      </Routes>
      <Appointment handleNavigationBtn={handleNavigation} />
      <Footer
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />
      <div className="whats-btn2">
        <a href="https://api.whatsapp.com/send?phone=13053704624" className="call-me-button">
          <i className="uil uil-whatsapp"></i>
        </a>
      </div>
      <div className="whats-btn">
        <a href="tel:+13053704624" className="call-me-button">
          <i className="uil uil-phone"></i>
        </a>
      </div>
    </div>
  );
}

export default App;
