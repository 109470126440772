import React, { useState } from 'react';
import './Faq.css';

function FAQ() {
  const faqData = [
    {
      question: 'What specialized acne treatments does Keila Domingues Skin Care offer?',
      answer:
        'Keila Domingues Skincare offers specialized acne treatments, including facials with extractions, light therapy, microdermabrasion, and organic peels, to combat acne and improve skin health. Teen acne treatments address hormonal changes and overactive oil glands, while a carefully selected range of at-home skincare products supports ongoing maintenance.'
    },
    {
      question: 'What benefits and options does the HydraFacial MD™ treatment at Keila Domingues Skin Care offer?',
      answer:
        'Keila Domingues Skin Care offers the HydraFacial MD™, a multi-step facial treatment that exfoliates, cleanses, extracts, and moisturizes using a patented device. This treatment addresses various skin concerns like acne, rosacea, wrinkles, and hyperpigmentation, often incorporating LED light therapy for enhanced results. Available in several packages, the HydraFacial MD™ can be personalized with boosters for targeted treatment, ensuring the best skin of your life.'
    },
    {
      question: 'What benefits does microdermabrasion offer for improving skin texture and appearance?',
      answer:
        'Microdermabrasion is a non-invasive cosmetic procedure that effectively exfoliates the skin to improve texture, reduce signs of aging, and promote even skin tone. It addresses issues like uneven texture, age spots, post-acne dark spots, melasma, and scars, resulting in a smoother, brighter complexion. This treatment is safe for all skin types and aims to enhance overall skin appearance.'
    },
    {
      question: 'What are the benefits and technology used in microneedling treatments at Keila DOmingues Skin Care?',
      answer:
        'Microneedling at Keila Domingues Skincare is a minimally invasive procedure that uses fine, sterile needles to create microscopic channels in the skin. This stimulates collagen and elastin production, improves blood circulation, and promotes cell turnover. It effectively treats acne scars, sun damage, stretch marks, and enhances overall skin appearance. Keila Domingues Skincare uses the FDA-cleared Exceed™ microneedling device for safe and clinically proven results, ensuring a rejuvenated complexion and long-lasting benefits.'
    },
    {
      question: 'What anti-aging treatments does Keila Domingues Skin Care offer to enhance skin health and appearance?',
      answer:
        'Keila Domingues Skin Care offers effective anti-aging treatments including dermaplaning to exfoliate and reveal healthy skin, LED light therapy for collagen stimulation and acne scar reduction, and microcurrent therapy for toning facial muscles and reducing fine lines. These treatments are designed to rejuvenate skin, improve texture, and enhance overall complexion for a more youthful appearance.'
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-wrapper">
      <div className='faq-container'>
        <h2>About Some Services</h2>
        <div className='faq-list'>
          {faqData.map((item, index) => (
            <div key={index} className='faq-item'>
              <div
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => handleToggle(index)}
              >
                <div className='question-text'>{item.question}</div>
                <div className='toggle-icon'>{activeIndex === index ? '-' : '+'}</div>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
