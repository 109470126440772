import React from "react";
import "./Footer.css";
import Logo from '../../assets/imgs/logo.png';

const Footer = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  return (
    <footer className="footer-background">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={Logo} alt="" />
          <p>
          Facial Spa - Keila Domingues
          Skin Care in Cooper City
          </p>
        </div>

        <div className="footer-item">
          <h5>PAGE</h5>
          <div>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button onClick={() => handleNavigationBtn(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </div>
        </div>

        <div className="footer-item">
          <h5>Skin Care in Cooper City</h5>
          <div className="especialidades">
            <p>HydraFacial</p>
            <p>Acne Treatment</p>
            <p>Microdermabrasion</p>
            <p>Microneedling</p>
            <p>Deep Facial</p>
            <p>And more</p>
          </div>
        </div>

        <div className="footer-item">
          <div>
            <h5>CONTACT</h5>
            <p>Email: kdyourskincare@gmail.com</p>
            <p>Phone: +1 (305) 370-4624</p>
          </div>
          <div>
            <h6>Address</h6>
            <a href="https://www.google.com/maps/place/Keila+Domingues+-+Skin+Care+-+Cooper+City/@26.089884,-80.398682,13z/data=!4m6!3m5!1s0x88d9a51fb2a2af3f:0xcf5dd520258b7c30!8m2!3d26.0436236!4d-80.2737122!16s%2Fg%2F11lkzkq8p7?hl=pt-PT&entry=ttu"> 9620 Stirling Rd Suite, 106</a>
            <br />
            <a href="https://www.google.com/maps/place/Keila+Domingues+-+Skin+Care+-+Cooper+City/@26.089884,-80.398682,13z/data=!4m6!3m5!1s0x88d9a51fb2a2af3f:0xcf5dd520258b7c30!8m2!3d26.0436236!4d-80.2737122!16s%2Fg%2F11lkzkq8p7?hl=pt-PT&entry=ttu">Cooper City, FL 33024</a>
          </div>
          
        </div>
      </div>

      <div className="footer-copy">
        <p>&copy; 2024 Kd SkinCare. All rights reserved.</p>
        <div className="footer-socials">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noreferrer">
              <i className={`${link.icon}`} aria-label={link.label}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;


