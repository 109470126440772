import React from "react";
import "./Mapa.css";
function Mapa() {
  return (
    <div className="mapa-container">
      <iframe
        className="mapa"
        title="Location"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14338.672927636693!2d-80.2737387!3d26.0444092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9a51fb2a2af3f%3A0xcf5dd520258b7c30!2sKeila%20Domingues%20-%20Skin%20Care%20-%20Cooper%20City!5e0!3m2!1spt-BR!2sbr!4v1720299128624!5m2!1spt-BR!2sbr"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
      
    </div>
  );
}

export default Mapa;
