import React from "react";
import "./InstaFeed.css";
import { InstagramEmbed } from "react-social-media-embed";

function InstaFeed() {
  return (
    <div className="instaFeed-wrapper">
      <div className="instaFeed-container">
        <h2>Follow Us on Instagram</h2>
        <h4>Connect with us on social media to stay updated on our latest treatments and posts!</h4>
        <div className="instaFeed-content">
          <div>
            <InstagramEmbed
              url="https://www.instagram.com/p/C3OAyFJNOd6/"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/C3ET8PVoBRV/"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/C7AlDCPKX8F/"
              width={328}
            />
          </div>
          <div>
            <InstagramEmbed
              url="https://www.instagram.com/p/C6ubonNt35W/"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/C5grAgiN-1F/"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/C39AKrzKi4V/"
              width={328}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstaFeed;
