import React from 'react';

import './MainBtn.css';

const Button = ({ link = '', alt, children = 'Book Now',  handleNavigationBtn}) => {

  const action ={
    label: 'Special Offers',
    type: 'Scroll',
    to: 'specialoffers',
  };

  const BtnAction = () => {
    handleNavigationBtn(action);
  };

    return (
        <button
            onClick={BtnAction}
            className={`main-btn ${alt ? 'alt' : ''}`}
        >
            {children}
        </button>
    );
};

export default Button;
