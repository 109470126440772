import React from 'react';
import './HeroContact.css';
import Slider from "react-slick";
import MainBtn from '../MainBtn/MainBtn';

const HeroContact = () => {
  return (
    <div className='herocontact-wrapper2'>
      <div className='herocontact-overlay2'></div>
      <div className='herocontact-container2'>
        <div className='herocontact-content'>
          <div className="texts-herocontact">
            <div>
              <h1>Our contact</h1>
            
            </div>
    
          </div>
        </div>

     
      </div>
    </div>
  );
};

export default HeroContact;

