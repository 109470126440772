import React from 'react';
import './Texto3.css';
import Asterisco from '../../assets/imgs/logo.png';

function Texto3() {
  return (
    <div className='Texto2-container'>
      <div className='Texto2-content'>
        <span>
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor DiscountS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          Get Your First Visitor Discount <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        </span>
      
      </div>
    </div>
  );
}

export default Texto3;
