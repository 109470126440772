import React, { useEffect, useRef } from 'react';
import './Home.css';
import Especialidades from '../especialidades/Especialidades'; // Ajuste o caminho conforme necessário
import MainBtn from '../MainBtn/MainBtn';

function Home({ handleNavigationBtn }) {


  const homeContentRef = useRef(null);
  const homeImgRef = useRef(null);
  const especialidadesRef = useRef(null); // Ref para o componente Especialidades

  const isElementInViewport = (el) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleScrollAnimation = () => {
    const homeContent = homeContentRef.current;
    const homeImg = homeImgRef.current;

    if (isElementInViewport(homeContent)) {
      homeContent.classList.add('animate-slide-left');
    }
    if (isElementInViewport(homeImg)) {
      homeImg.classList.add('animate-slide-right');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollAnimation);
    return () => {
      window.removeEventListener('scroll', handleScrollAnimation);
    };
  }, []);

  const handleButtonClick = () => {
    if (especialidadesRef.current) {
      especialidadesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='home-wrapper'>
      <div className='home-container'>
        <div className='home-content' ref={homeContentRef}>
          <h2>Welcome</h2>
          <h5>KD is a space for self-care.</h5>
          <p>
            Welcome to your sanctuary of self-care and beauty. At KD, we go beyond standard treatments; we offer a haven where respect, ethics, transparency, and a commitment to your well-being shape every aspect of our service.
          </p>
          <p>
            Experience personalized treatments catered uniquely to your skin's needs, utilizing advanced techniques and high-quality products. Our dedicated team of experts is committed to surpassing your expectations with technical excellence providing visible results from your very first visit. Trust in our integrity and dedication as we help you reconnect with yourself, rejuvenating both your inner and outer beauty.
          </p>
          <p>Discover the path to radiant skin at KD Skin Care — where luxury meets transformative skin care.</p>

          <MainBtn handleNavigationBtn={handleNavigationBtn} children='Book Appointment'/>
        </div>
        <div className='home-img' ref={homeImgRef}>
          <iframe
            className="home-video"
            src="https://www.youtube.com/embed/CMdiUCnW1ek"
            title="KD SKIN CARE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <br />
      <br />
     
    </div>
  );
}

export default Home;
