const socialLinks = [
    {
        url: 'https://www.instagram.com/keiladominguesskincare/',
        label: 'Instagram icon',
        icon: 'uil uil-instagram',
    },
    {
        url: 'https://api.whatsapp.com/send?phone=13053704624',
        label: 'Whatsapp Icon',
        icon: 'uil uil-whatsapp',
    },
    {
        url: 'https://www.facebook.com/KeilaDominguesskincare/',
        label: 'Facebook icon',
        icon: 'uil uil-facebook-f',
    }
];

export default socialLinks;
