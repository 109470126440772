import React from 'react';
import './Testimonials.css';
import MainBtn from '../MainBtn/MainBtn';
import T1 from "../../assets/imgs/t1.png"
import T2 from "../../assets/imgs/t2.png"
import T3 from "../../assets/imgs/t3.png"
import T4 from "../../assets/imgs/t4.png"
import T5 from "../../assets/imgs/t5.png"
import T6 from "../../assets/imgs/t6.png"


function Testimonials() {
  return (
    <div className='testimonials-wrapper'>
      <div className='testimonials-container'>
        <div className='testimonials-content'>
          <h2>Testimonials</h2>
          <p>What our clients say</p>
          


        </div>
        <div className='testimonials-img'>
          <a href="https://www.google.com/search?q=keila+domingues+-+skin+care+-+cooper+city&oq=keila+doming&gs_lcrp=EgZjaHJvbWUqEAgAEEUYExgnGDsYgAQYigUyEAgAEEUYExgnGDsYgAQYigUyBggBEEUYQDIGCAIQRRg5MgkIAxAAGBMYgAQyCQgEEAAYExiABDIGCAUQRRg9MgYIBhBFGDwyBggHEEUYPKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0x88d9a51fb2a2af3f:0xcf5dd520258b7c30,1,,,,">
            <img src={T6} alt="" /> </a>
            <a href="https://www.google.com/search?q=keila+domingues+-+skin+care+-+cooper+city&oq=keila+doming&gs_lcrp=EgZjaHJvbWUqEAgAEEUYExgnGDsYgAQYigUyEAgAEEUYExgnGDsYgAQYigUyBggBEEUYQDIGCAIQRRg5MgkIAxAAGBMYgAQyCQgEEAAYExiABDIGCAUQRRg9MgYIBhBFGDwyBggHEEUYPKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0x88d9a51fb2a2af3f:0xcf5dd520258b7c30,1,,,,">
            <img src={T5} alt="" /> </a>
            <a href="https://www.google.com/search?q=keila+domingues+-+skin+care+-+cooper+city&oq=keila+doming&gs_lcrp=EgZjaHJvbWUqEAgAEEUYExgnGDsYgAQYigUyEAgAEEUYExgnGDsYgAQYigUyBggBEEUYQDIGCAIQRRg5MgkIAxAAGBMYgAQyCQgEEAAYExiABDIGCAUQRRg9MgYIBhBFGDwyBggHEEUYPKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0x88d9a51fb2a2af3f:0xcf5dd520258b7c30,1,,,,">
            <img src={T4} alt="" /> </a>
         
        </div>
        <p><span className="bold-text">Google rating</span> score: 3 of 3,based on <span className="bold-text">173 reviews.</span></p>
      </div>
    </div>
  );
}

export default Testimonials;
