import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Especialidades.css";
import "./Modal.css";

import face from "../../assets/imgs/facials.svg";
import MainBtn from "../MainBtn/MainBtn";
import ImgFace from "../../assets/imgs/2.png";
import ImgForm from "../../assets/imgs/keilaformori.jpeg";

function Especialidades() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    treatmentPreference: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form submitted:", formData);

    const emailSubject = `Booking Inquiry: ${formData.treatmentPreference}`;
    const emailBody = `Hello, my name is ${formData.name}. I visited your website and I'm interested in booking a session for the ${formData.treatmentPreference} treatment. This will be my first visit, and I would like to know if there is a discount for first-time visitors. Could you please provide more information about your services and availability? Thank you!\n\nPhone: ${formData.phone}\nEmail: ${formData.email}\nMessage: ${formData.message}`;

    const emailLink = `mailto:keila@keilaskincare.com?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;

    // Debugging: Log the generated email link
    console.log("Generated email link:", emailLink);

    // Trigger the email link
    window.location.href = emailLink;
  };

  const treatments = [
    {
      name: "Dermaplaning Luxury + Oxygen Therapy",
      description: "Smoother skin: Dermaplaning can make skin look brighter and smoother...",
      duration: "Duration: 60 minutes",
      price: "$120",
    },
    {
      name: "HydraFacial Signature + Deep Facial VIP",
      description: "Manual extraction, deeply cleanses and exfoliates with a gentle peel...",
      duration: "Duration: 90 minutes",
      price: "$249",
    },
    {
      name: "HydraFacial VIP with Booster + Dermaplaning",
      description: "Manual extraction, deeply cleanses and exfoliates with a gentle peel...",
      duration: "Duration: 120 minutes",
      price: "$299",
    },
    {
      name: "HydraFacial VIP with Booster + Hydrafacial Eyes or Lips",
      description: "Manual extraction, deeply cleanses and exfoliates with a gentle peel...",
      duration: "Duration: 120 minutes",
      price: "$299",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
  };

  return (
    <div className="especialidades-wrapper">
      <div className="especialidades-container">
        <div className="header">
          <br />
          <h1>Special Offers</h1>
          <h3>Exclusive Offer for First-Time Visitors</h3>
        </div>
        <div className="treatments-grid">
          {treatments.map((treatment, index) => (
            <div key={index} className="card">
              <h4 className="treatment-name">{treatment.name}</h4>
              <p className="treatment-description">{treatment.description}</p>
              <div>
                <p className="treatment-duration">{treatment.duration}</p>
                <p className="treatment-price">{treatment.price}</p>
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
        <br />
        <div></div>
        <div id="especialidades" className="form-section">
          <div className="formflex2">
            <img src={ImgForm} alt="" className="img-large" />
          </div>
          <div className="formflex">
            <h2>Unlock the Secret to Radiant Skin!</h2>
            <br />
            <div className="text-form">
              <p>
                Discover the ultimate path to a flawless complexion with Keila Domingues Skin Care...
              </p>
              <br />
            </div>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <br />
              <label>
                Phone:
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <br />
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <br />
              <label>
                Treatment Preference:
                <input
                  type="text"
                  name="treatmentPreference"
                  value={formData.treatmentPreference}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <br />
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <br />
              <div className="text-form">
                <p>
                  * Please fill out the form, and we will contact you within one hour during business hours...
                </p>
              </div>
              <br />
              <br />
              <button type="submit" className="whatsapp-msg">
                Schedule your session
              </button>
              <br />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Especialidades;
